import * as React from 'react'
import { useP } from '../../services/i18n';

type ErrorType = {
    error?: string | null
}

function ErrorTicket({ error }: ErrorType) {

    const p = useP();

    return (
        <div role="alert" className={'font-bold text-red-500 flex flex-1 align-middle justify-center h-full'}>
            <p>{p.t('error.ticketNotLoaded')}</p>
            {error && <pre>{error}</pre>}
        </div>
    )
}

export default ErrorTicket;