import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import Button from '../../../../../Components/Button'
import Spinner from '../../../../../Components/Spinner'
import { luhnCheck, toastError, toastSuccess } from '../../../../../Components/Utils/Utils'
import { useP } from '../../../../../services/i18n'
import { SUBMIT_BON_COMMANDE } from '../../../queries'
import * as Sentry from "@sentry/react";
import { useCheckFeatureFlag } from '../../../../../Components/CheckFeatureFlag/CheckFeatureFlag'
import Constants from "../../../../../commons/Constants";
import Modal from '../../../../../Components/Modal/Modal'
import Toggle from '../../../../../Components/Toggle'
import { Input } from '../../../../../Components/Form/Input'

const {
  FEATURE_FLAG_ALPHALINK_ESIM,
  BDC_FOURNISSEUR_ALPHALINK
} = Constants;

type ActionBonCommandeSimSwapType = {
  livraisonId: number,
  action: string,
  label: string,
  message: string|JSX.Element,
  prestationId: number,
  disabled?: boolean,
  bonDeCommandeModele?: {fournisseur: string, modele: string}
}

export default function ActionBonCommandeSimSwap({ disabled=false, livraisonId, action, label, message, prestationId, bonDeCommandeModele }: ActionBonCommandeSimSwapType) {

  const p = useP();

const dataOnly = (bonDeCommandeModele?.modele === 'EMADO');
const { checkFeatureFlag } = useCheckFeatureFlag();
  const esimForm = bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ALPHALINK && !dataOnly ? checkFeatureFlag(FEATURE_FLAG_ALPHALINK_ESIM) : false;
  const [isEsim, setIsEsim] = useState(false);
  const [sim, setSim] = useState('');
  const [eSimContactMail, setESimContactMail] = useState('');


  const [submitBonCommande, { loading }] = useMutation(SUBMIT_BON_COMMANDE);

  const submitAction = () => {
   const values = [{key: 'sim', value: sim}];
    if(esimForm && isEsim){
      values.push({key: 'eSimContactMail', value: eSimContactMail})
      values.push({key: 'eSim', value: 'true'})
    }

     submitBonCommande({
      variables: {
        bonCommandeInput: {
          action,
          livraisonId: livraisonId,
          values: values,
          prestationId: prestationId,
        }
      }
    }).then(({ data }) => {

      if (data.submitBonCommande.success) {
        toastSuccess(p.t('prestations.gsm.actions.submitBonCommandeSuccessMessage'));
      } else {

        const codeErreur = data?.submitBonCommande?.codeErreur;
        const tradErreur = `prestations.gsm.errors.${codeErreur}`
        const erreurMessage = p.has(tradErreur) ? p.t(tradErreur) : `${p.t('error.defaultMessage')} (Err: ${codeErreur})`

        console.error(data.submitBonCommande.message)
        Sentry.captureException(data.submitBonCommande.message);
        toastError(erreurMessage);
      }
    })
      .catch((error) => {
        Sentry.captureException(error);
        toastError(p.t('error.defaultMessage'));
      });
  }

  // Modale de confirmation
  const [modaleIsOpen, setModaleIsOpen] = useState(false)

  const checkValues=() => {
    let simOk = true;
    if(isEsim){
        //simOk = true si esimcontactmail est un email
        simOk = !!eSimContactMail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
        if (!simOk)
          toastError(p.t('form.email'));
    }else{
      if (bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ALPHALINK) {
        simOk = luhnCheck(sim);
        if (!simOk)
          toastError(p.t('form.checkLuhn'));
      } else { 
        simOk = !!sim.match(/^[A-Z0-9+]+$/);
        if (!simOk)
          toastError(p.t('form.incorrectChar'));
      }
    }
    return simOk;
  };

  const onClose=(action) => {
    if (action) {
      if (!checkValues())
        return;
      submitAction();
    }
    setModaleIsOpen(false);
  };

  useEffect (() => {
    if(isEsim){
      setSim('');
    }else{
      setESimContactMail( '');
    }
  }, [isEsim])

  return <>
    <div className=''>
      <Button
        disabled={loading || disabled}
        color='primary'
        size='small'
        onClick={() => setModaleIsOpen(true)}>
        {loading ? <Spinner /> : label}
      </Button>
    </div>

    <Modal
      onClose={() => onClose(false)}
      isOpen={modaleIsOpen}
      content={{
        title: <>{message}</>,
        body: <div className="p-2">
          {
            esimForm && <div className="sm:col-span-12 mb-2">
              <Toggle onToggle={setIsEsim} value={isEsim} label={p.t('commandeGSM.form.esim')}/>
            </div>
          }
          {
            (!esimForm || (esimForm && !isEsim)) &&
          <div className="sm:col-span-12">
            <Input className="uppercase" onChange={(event) => setSim( event.target.value.toUpperCase().replace(/\s/g, ''))} name="sim" label={p.t('commandeGSM.form.sim')} type='text' autoComplete='off' value={sim} />
          </div>
          }
          {esimForm && isEsim && 
            <div className="sm:col-span-12">
              <Input onChange={(event) => setESimContactMail( event.target.value.replace(/\s/g, ''))}  name="eSimContactMail" label={p.t('commandeGSM.form.eSimContactMail')} type='text' autoComplete='off' />
            </div>
          }          
        </div>,
        footer: <div className="flex justify-end items-center w-full">
          <div className="flex items-center">
            <div className="flex items-center">
              <Button
                type="button"
                color="primaryLight"
                onClick={() => onClose(false)}
                className="mr-4">{p.t('actions.annuler')}</Button>
              <Button
                type="button"
                color="primary"
                disabled={!sim && !eSimContactMail}
                onClick={() => onClose(true)}
                className="">{p.t('actions.ok')}</Button>
            </div>
          </div>
        </div>,
      }}
      className="w-full lg:w-2/6"
    />
  </>
}