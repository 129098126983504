import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { I18n as I18nProvider } from './services/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Constants from './commons/Constants';

import secureLocalStorage from "react-secure-storage";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getRedirUrl } from './utils/utils';
import { getSupervisionData } from './Supervision/utils';

const {
  API_URL,
  SENTRY_URL,
  NODE_ENV,
  BUILD_ID = "Non défini",
  BUILD_NUMBER = "Non défini",
} = Constants;

/**
 * Release informations
 */
console.log(
  `%cBLUE - Espace Client\nBuild ID: ${BUILD_ID}\nBuild Number: ${BUILD_NUMBER}`,
  "font-weight: bold; font-size: 1.2em; color: #16a34a"
);

const httpLink = createHttpLink({});

const apiLink = setContext((_, { headers, clientName }) => {

  const account: any = secureLocalStorage.getItem("loggedInUser");
  const token = account?.token;

  const url = window.location.pathname.split('/');

  let selectedCompte;

  if (url.length && url[1] === "comptes") {
    selectedCompte = sessionStorage.getItem('selectedCompte');
  }
  else {
    selectedCompte = url.length > 2 && Number.isFinite(Number(url[2])) ? url[2] : account?.compte?.id;
  }

  return {
    headers: {
      ...headers,
      authorization: token ? token : "",
      "selected-compte": selectedCompte || ''
    },
    uri: API_URL,
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.extensions?.code) {
        case "UNAUTHENTICATED":
          console.log("UNAUTHENTICATED");
          secureLocalStorage.removeItem("loggedInUser");
          window.location.href = `/login?expired=true&redir=${getRedirUrl({ encode: true })}`;
          break;
      }
    }
  }

  if (networkError) {

    const onResponse = (res = { statut: 'ERROR' }) => {
      if (res.statut !== 'ACTIF') {
        window.location.href = "/supervision"
      }
    }

    getSupervisionData(onResponse, onResponse)

  }
});

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: NODE_ENV,
    integrations: [new BrowserTracing()],

    tracesSampleRate: 0.1,
  });
}

const client = new ApolloClient({
  link: from([errorLink, apiLink, httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <I18nProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </I18nProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
