/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import ModalCommandeGSM from "./ModalCommandeGSM/ModalCommandeGSM";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useP } from "../../services/i18n";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { COMMANDER_LIGNE, GET_OPTIONS, GET_MSISDNS } from "./queries";
import { luhnCheck, toastError, toastSuccess } from "../../Components/Utils/Utils";
import Constants from "../../commons/Constants";
import dayjs from "dayjs";
import { BonDeCommandeModeleType } from "../../types/BonDeCommandeModeleType";
import * as Sentry from "@sentry/react";
import { AnyObject, Maybe } from "yup/lib/types";
import { RequiredStringSchema } from "yup/lib/string";
import { useCheckFeatureFlag } from "../../Components/CheckFeatureFlag/CheckFeatureFlag";


declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    checkLuhn(): RequiredStringSchema<TType, TContext>;
  }
}

type CommandeGSMType = {
  isOpen: boolean,
  onCloseModalCommandeGSM: () => void,
  abonnementId: number,
  bonDeCommandeModele: BonDeCommandeModeleType
}

const {
  GSM_FOURNISSEUR_EIT,
  GSM_FOURNISSEUR_ALPHALINK,
  FEATURE_FLAG_ALPHALINK_ESIM,
  BDC_FOURNISSEUR_ALPHALINK
} = Constants;

export default function CommandeGSM({
  isOpen,
  onCloseModalCommandeGSM,
  abonnementId,
  bonDeCommandeModele
}: CommandeGSMType) {

  const p = useP();

  yup.addMethod<yup.StringSchema>(yup.string, "checkLuhn", function () {
    return this.test('luhnCheck', p.t('form.checkLuhn'), function (value) {
      const { createError } = this;
      return luhnCheck(value) || createError()
    })
  });

  const dataOnly = (bonDeCommandeModele?.modele === 'EMADO');
  const { checkFeatureFlag } = useCheckFeatureFlag();
  const esimForm = bonDeCommandeModele?.fournisseur === BDC_FOURNISSEUR_ALPHALINK && !dataOnly ? checkFeatureFlag(FEATURE_FLAG_ALPHALINK_ESIM) : false;
  console.log('esimForm', esimForm)
  console.log('dataOnly', dataOnly)
  console.log('bonDeCommandeModele', bonDeCommandeModele)
  const mobilePhoneRegExp = /^0[6-7][0-9]{8}$/

  const validationSchema = yup.object().shape({
    contact: yup.object().required(p.t('form.required')),
    isEsim: yup.boolean(),
    sim: yup.string().when('isEsim', {
      is: false,
      then: yup.string().when([], {
        is: () => bonDeCommandeModele?.fournisseur === "ALPHALINK",
        then: yup.string().checkLuhn().required(p.t('form.required')),
        otherwise: yup.string().matches(/^[A-Z0-9+]+$/, p.t('form.incorrectChar')).required(p.t('form.required'))
      }),
      otherwise: yup.string().notRequired()
    }),
    rio: yup.string().notRequired().test('rio', p.t('form.incorrectChar'), (value) => {
      if (value != "") {
        const schema = yup.string().matches(/^[A-Z0-9+]+$/, p.t('form.incorrectChar'));
        return schema.isValidSync(value);
      }
      return true
    }),
    numLigne: yup.object().when('rio', (rio, schema) => {
      if (dataOnly) return schema;
      return (rio) ? schema : schema.required(p.t('form.required'))
    }),
    numLignePorta: yup.string().when('rio', (rio, schema) => {
      if (rio != '') {
        if (dataOnly) return schema;
        return (rio) ? schema.matches(mobilePhoneRegExp, p.t('form.mobilePhoneFormat')).required(p.t('form.required')) : schema.required(p.t('form.required'))
      } else {
        return schema.notRequired()
      }
    }),
    eSimContactMail: yup.string().email(p.t('form.email')).when('isEsim', {
      is: true,
      then: yup.string().email(p.t('form.email')).required(p.t('form.required')),
      otherwise: yup.string().notRequired()
    }),
    affectation: yup.string().nullable(),
    matricule: yup.string().nullable(),
  });

  const { getValues, setValue, register, control, handleSubmit, formState: { errors, isDirty }, reset } = useForm<any>({
    resolver: yupResolver(validationSchema),
    mode: "all"
  });
  const [modalIsOpen, setModalIsOpen] = useState(isOpen)
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)

  useEffect(() => {
    isOpen ? setModalIsOpen(true) : setModalIsOpen(false)

    if (isOpen) {
      reset()
      getMsisdns();
    }

  }, [isOpen])

  const [getMsisdns, { data: dataMsisdn }] = useLazyQuery(GET_MSISDNS, {
    variables: {
      filters: [
        {
          column: "dispo",
          value: "1"
        },
        {
          column: "fournisseur_id",
          value: `${bonDeCommandeModele.fournisseur === 'EIT' ? GSM_FOURNISSEUR_EIT : GSM_FOURNISSEUR_ALPHALINK}`
        }
      ],
      sorters: [
        {
          column: "msisdn",
        }
      ],
      pagination: {
        limit: 20
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const { loading: loadingOptionsGsm, data: dataOptionsGsm } = useQuery(GET_OPTIONS, {
    variables: {
      filters: [
        {
          column: "date",
          operator: "GT",
          value: dayjs().format('YYYY-MM-DD')
        }
      ],
      sortersSite: [
        { column: "nom" }
      ],
      abonnementId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [commanderLigne, { loading: loadingCommanderLigne }] =
    useMutation(COMMANDER_LIGNE);

  const onSubmit: SubmitHandler<any> = async data => {
    const dataPost = [{
      key: "sim",
      value: data.sim
    },
    {
      key: "msisdn",
      value: data.rio ? data.numLignePorta : data?.numLigne?.code
    },
    {
      key: "contactId",
      value: data.contact.id
    },
    {
      key: "matricule",
      value: data.matricule
    },
    {
      key: "affectation",
      value: data.affectation
    },
    {
      key: "dateEffet",
      value: data.rio ? data.dateporta : ''
    },
    {
      key: "rio",
      value: data.rio
    },
    {
      key: "siteId",
      value: data?.siteId?.code
    }];
    if(esimForm){
      dataPost.push({
        key: "eSimContactMail",
        value: data?.eSimContactMail
      })
    }
    
    commanderLigne({
      variables: {
        bonCommandeInput: {
          abonnementId: parseInt(abonnementId.toString(), 10),
          values: dataPost
        }
      }
    }).then(({ data }) => {
      if (data.submitCommandeGSM.success) {
        toastSuccess(p.t('commandeGSM.form.successMessage'));
        setModalIsOpen(false)
        onCloseModalCommandeGSM()
      } else {

        const codeErreur = data?.submitCommandeGSM?.codeErreur;
        const tradErreur = `prestations.gsm.errors.${codeErreur}`
        const erreurMessage = p.has(tradErreur) ? p.t(tradErreur) : `${p.t('error.defaultMessage')} (Err: ${codeErreur})`

        console.error(data.submitCommandeGSM.message)
        Sentry.captureException(data.submitCommandeGSM.message);
        toastError(erreurMessage);
      }
    })
      .catch((error) => {
        Sentry.captureException(error);
        toastError(p.t('error.defaultMessage'));
      });
  };

  const closeModalCommandeGSM = () => {
    if (isDirty) {
      setConfirmIsOpen(true)
    }
    else {
      setModalIsOpen(false)
      onCloseModalCommandeGSM()
    }
  }

  const confirmAction = (confirm) => {
    if (confirm) {
      setModalIsOpen(false)
      onCloseModalCommandeGSM()
    }
    setConfirmIsOpen(false)
  }

  const sites = dataOptionsGsm?.sites || []
  const siteDefaut = dataOptionsGsm?.abonnement?.abonnementGroupe?.site;
  let selectedSite = siteDefaut;

  const siteFound = sites.find((site) => site.id === siteDefaut?.id);
  if (siteFound) {
    selectedSite = siteFound;
  } else if (sites.length > 0) {
    selectedSite = sites[0];
  }

  

  return <>
    <ModalCommandeGSM
      loading={loadingCommanderLigne || loadingOptionsGsm}
      isOpen={modalIsOpen}
      onClose={() => closeModalCommandeGSM()}
      register={register}
      control={control}
      getValues={getValues}
      setValue={setValue}
      errors={errors}
      saveForm={handleSubmit(onSubmit)}
      confirmOpen={confirmIsOpen}
      confirmMessage={p.t('commandeGSM.form.confirmMessage')}
      confirmAction={(confirm => confirmAction(confirm))}
      msisdns={dataMsisdn?.gsmMsisdns ? dataMsisdn.gsmMsisdns.items : []}
      reloadMsisdn={getMsisdns}
      joursFeries={dataOptionsGsm?.joursFeries?.items ? dataOptionsGsm.joursFeries.items.map((jf) => jf.date) : []}
      forfait={dataOptionsGsm?.abonnement?.description}
      sites={sites}
      siteDefaut={selectedSite}
      dataOnly={dataOnly}
      esim={esimForm}
      compte={dataOptionsGsm?.abonnement?.devis?.client?.compte}
    />
  </>
}