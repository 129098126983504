import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Constants from '../../../../../commons/Constants'
import List from '../../../../../Components/List/List'
import Spinner from '../../../../../Components/Spinner'
import { useP } from '../../../../../services/i18n'
import { OPTIONS_ACTION } from '../../../queries'
import ActionTelechargerCdr from './ActionTelechargerCdr'
import ActionBonCommande from './ActionBonCommande'
import ActionBonCommandePrompt from './ActionBonCommandePrompt'
import ActionChangerSite from './ActionChangerSite'
import ActionVisualiserData from './ActionVisualiserData'
import TooltipInformation from '../../../../../Components/Tooltip/TooltipInformation'
import ActionChangerForfait from './ActionChangerForfait'
import ActionBonCommandeSimSwap from './ActionBonCommandeSimSwap'

type GSMActionsType = {
  livraisonId: string,
  ligne: any,
  prestationId: number | string
  siteId: number | string,
  forfait:any
}

const {
  ETAT_LIGNE_SUSPENDUE,
  ETAT_LIGNE_RESILIEE,
  ETAT_LIGNE_EN_SERVICE,
  GSM_FOURNISSEUR_EIT,
  GSM_FOURNISSEUR_ALPHALINK
} = Constants;

export default function GSMActions({ livraisonId, ligne, prestationId,siteId,forfait }: GSMActionsType) {

  const p = useP();

  const ACTION_SUSPENDRE = "SUSPENDRE";
  const ACTION_REACTIVER = "REACTIVER";
  const ACTION_RESILIER = "RESILIER";
  const ACTION_RECHARGER_BALANCES = "RECHARGER_BALANCES";
  const ACTION_REVALORISER = "REVALORISER";
  const ACTION_SIMSWAP = "SIMSWAP";

  //Récupération des datas pour les actions
  const { loading: loadingOptionsAction, data: dataOptionsAction } = useQuery(OPTIONS_ACTION, {
    variables: {
      filtersPrestation:[{
        column: "id",
        value: prestationId
      }],
      livraisonId: parseInt(livraisonId),
      sorters: [
        {
          "column": "codePostal"
        },
        {
          "column": "commune"
        },
        {
          "column": "nom"
        }
      ]
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const [actionsListe, setActionListe] = useState<any[]>([])
  useEffect(() => {
    const actions: any[] = [];

    if (ligne?.etat === ETAT_LIGNE_EN_SERVICE) {
      actions.push({
        id: 0,
        libelle: <div className=''>
          <ActionBonCommande
            action={ACTION_SUSPENDRE}
            livraisonId={parseInt(livraisonId.toString(), 10)}
            label={p.t('prestations.gsm.actions.suspendreLigne')}
            message={p.t('prestations.gsm.actions.confirmSuspendreLigne')}
            prestationId={parseInt(prestationId.toString(), 10)}
          />
        </div>
      })

      if (ligne?.gsmSim?.fournisseurId === GSM_FOURNISSEUR_EIT) {
        actions.push({
          id: 3,
          libelle: <div className=''>
            <ActionBonCommande
              action={ACTION_RECHARGER_BALANCES}
              livraisonId={parseInt(livraisonId.toString(), 10)}
              label={p.t('prestations.gsm.actions.reinitialiserBalanceDataLigne')}
              message={p.t('prestations.gsm.actions.confirmReinitialiserBalanceDataLigne')}
              prestationId={parseInt(prestationId.toString(), 10)}
            />
          </div>
        })
      }

      if (ligne?.gsmSim?.fournisseurId === GSM_FOURNISSEUR_ALPHALINK) {
        actions.push({
          id: 3,
          libelle: <div className=''>
            <ActionBonCommandePrompt
              action={ACTION_RECHARGER_BALANCES}
              keyValue={dataOptionsAction?.optionsRechargeData?.key}
              livraisonId={parseInt(livraisonId.toString(), 10)}
              label={p.t('prestations.gsm.actions.rechargerBalanceDataLigne')}
              message={p.t('prestations.gsm.actions.promptRechargeData')}
              inputLabel={p.t('prestations.gsm.actions.promptRechargeDataLabel')}
              inputType='select'
              selectDatas={dataOptionsAction?.optionsRechargeData?.values ? dataOptionsAction.optionsRechargeData.values.map((v) => ({ code: v.value, libelle: v.label })) : []}
              prestationId={parseInt(prestationId.toString(), 10)}
            />
          </div>
        })
      }

      actions.push({
        id: 5,
        libelle: <div className=''>
          <ActionBonCommandeSimSwap
            action={ACTION_SIMSWAP}
            bonDeCommandeModele={dataOptionsAction?.prestations?.items[0]?.abonnement?.article?.bonDeCommandeModele}
            livraisonId={parseInt(livraisonId.toString(), 10)}
            label={p.t('prestations.gsm.actions.simswapLigne')}
            message={p.t('prestations.gsm.actions.promptSimSwapLigne')}
            prestationId={parseInt(prestationId.toString(), 10)}
          />
        </div>
      })

      actions.push({
        id: 6,
        libelle: <ActionChangerForfait
          bdcEnCours={dataOptionsAction?.prestations?.items[0]?.bdcEnCours || null}
          action={ACTION_REVALORISER}
          livraisonId={parseInt(livraisonId.toString(), 10)}
          prestationId={parseInt(prestationId.toString(), 10)}
          siteId={parseInt(siteId.toString(), 10)}
          forfaits={dataOptionsAction?.optionsChangementForfait || [] }
          forfait={forfait}
        />
      })

      actions.push({
        id: 8,
        libelle: <div className=''>
          <ActionVisualiserData
            livraisonId={parseInt(livraisonId.toString(), 10)}
          />
        </div>
      })

    }

    if (ligne.etat === ETAT_LIGNE_SUSPENDUE) {
      actions.push({
        id: 1,
        libelle: <div className=''>
          <ActionBonCommande
            action={ACTION_REACTIVER}
            livraisonId={parseInt(livraisonId.toString(), 10)}
            label={p.t('prestations.gsm.actions.reactiverLigne')}
            message={p.t('prestations.gsm.actions.confirmReactiverLigne')}
            prestationId={parseInt(prestationId.toString(), 10)}
          />
        </div>
      })
    }

    if (ligne?.etat !== ETAT_LIGNE_RESILIEE) {
      actions.push({
        id: 2,
        libelle: <div className=''>
          <ActionBonCommandePrompt
            action={ACTION_RESILIER}
            keyValue='dateResil'
            livraisonId={parseInt(livraisonId.toString(), 10)}
            label={p.t('prestations.gsm.actions.resilierLigne')}
            message={p.t('prestations.gsm.actions.promptResilierLigne')}
            inputLabel={p.t('prestations.gsm.actions.promptResilierLabel')}
            inputType='date'
            prestationId={parseInt(prestationId.toString(), 10)}
          />
        </div>
      })
    }

    actions.push({
      id: 4,
      libelle: <div className='flex items-center space-x-2'>
        <ActionTelechargerCdr ligne={ligne} prestationId={prestationId} />
        <TooltipInformation contenu={<div className='max-w-[350px]'>{p.t('prestations.gsm.actions.infoTelechargerCdr')}</div>} /></div>
    })

    actions.push({
      id: 7,
      libelle: <div className=''>
        <ActionChangerSite
          prestationId={parseInt(prestationId.toString(), 10)}
          selectDatas={dataOptionsAction?.sites ? dataOptionsAction.sites.map((s) => ({ code: s.id, libelle: `${s.codePostal ? s.codePostal : ''} ${s.commune ? s.commune : ''} - ${s.nom}` })) : []}
        />
      </div>
    })

    setActionListe(actions)
  }, [ligne, dataOptionsAction])

  return <>
    <div className='lg:w-3/12 lg:mr-4 rounded-md border border-gray-200 mb-4 lg:mb-0'>
      <h2 className='text-primary-700 font-medium text-md px-4 pt-4'>{p.t('prestations.detail.actions')}</h2>
      <div className='h-1 w-[100px] bg-secondary-200 my-2 ml-4 rounded-full'></div>
      <div className='pt-2 px-4'>
        {loadingOptionsAction ? <Spinner /> : <List datas={actionsListe} canDeleteItem={false} />}
      </div>
    </div>
  </>
}