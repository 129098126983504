import React, { useState } from 'react';

interface ToggleProps {
  label: string;
  value: boolean;
  onToggle: (isOn: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({ label, onToggle, value }) => {
  const [isOn, setIsOn] = useState(value);

  const handleToggle = () => {
    setIsOn(!isOn);
    onToggle(!isOn);
  };

  return (
    <div className="flex items-center space-x-2">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <button
        type="button"
        onClick={handleToggle}
        className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ${isOn ? 'bg-indigo-600' : 'bg-gray-200'}`}
      >
        <span
          aria-hidden="true"
          className={`inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${isOn ? 'translate-x-5' : 'translate-x-0'}`}
        />
      </button>
    </div>
  );
};

export default Toggle;